import React, { useState } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

import t from "../locale";

const Numbers = ({ lang }) => {
  const [focus, setFocus] = useState(false);

  return (
    <section className="numbers">
      <div className="container container--big numbers__holder">
        <div className="holder__line"></div>
        <div className="holder__line"></div>
        <div className="holder__line"></div>
        <div className="holder__line"></div>
        <div className="holder__line"></div>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-6 col-xl-4 offset-xl-2 numbers__item numbers__item--half">
            <p className="item__number number color--white">
              <CountUp
                start={focus ? 0 : null} // start={focus ? startCounterOne : null}
                end={30000000} // end={counterOne}
                duration={6} // duration={startDurationOne}
                redraw={true}
                separator=" "
                // onUpdate={() => { setStartCounterOne(counterOne); setCounterOne(counterOne + 1); }}
                // onEnd={ ({ update }) => { update(counterOne + 1); setStartDurationOne(5); } }
              >
                {({ countUpRef }) => (
                  <VisibilitySensor
                    onChange={(isVisible) => {
                      if (isVisible) {
                        setFocus(true);
                      }
                    }}
                  >
                    <span ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
            </p>
            <p className="item__text color--white">
              {t("wyprodukowane palety", lang)}
            </p>
          </div>
          <div className="col-12 col-md-6 col-lg-6 col-xl-4 numbers__item numbers__item--half">
            <p className="item__number number color--white">
              <CountUp
                start={focus ? 0 : null} // start={focus ? startCounterTwo : null}
                end={60000} // end={counterTwo}
                dduration={6} // uration={startDurationTwo}
                redraw={true}
                separator=" "
                // onUpdate={() => { setStartCounterTwo(counterTwo); setCounterTwo(counterTwo + 1); }}
                // onEnd={ ({ update }) => { update(counterTwo + 1); setStartDurationTwo(1800) } }
              >
                {({ countUpRef }) => (
                  <VisibilitySensor
                    onChange={(isVisible) => {
                      if (isVisible) {
                        setFocus(true);
                      }
                    }}
                  >
                    <span ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
            </p>
            <p className="item__text color--white">
              {t("załadowane tiry", lang)}
            </p>
          </div>
          <div className="col-12 col-md-6 col-lg-6 col-xl-4 offset-xl-2 numbers__item numbers__item--half">
            <p className="item__number number color--white">
              <CountUp
                start={focus ? 0 : null} // start={focus ? startCounterThree : null}
                end={100000} // end={counterThree}
                duration={6} // duration={startDurationThree}
                redraw={true}
                separator=" "
                // onUpdate={() => { setStartCounterThree(counterThree); setCounterThree(counterThree + 1); }}
                // onEnd={ ({ update }) => { update(counterThree + 1); setStartDurationThree(3600); } }
              >
                {({ countUpRef }) => (
                  <VisibilitySensor
                    onChange={(isVisible) => {
                      if (isVisible) {
                        setFocus(true);
                      }
                    }}
                  >
                    <span ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
            </p>
            <p className="item__text color--white">
              {t("zrealizowane zamówienia", lang)}
            </p>
          </div>
          <div className="col-12 col-md-6 col-lg-6 col-xl-4 numbers__item numbers__item--half">
            <p className="item__number number color--white">
              <CountUp
                start={focus ? 0 : null} // start={focus ? startCounterFour : null}
                end={1200000} // end={counterFour}
                duration={6} // duration={startDurationFour}
                redraw={true}
                separator=" "
                // onUpdate={() => { setStartCounterFour(counterFour); setCounterFour(counterFour + 1); }}
                // onEnd={ ({ update }) => { update(counterFour + 1); setStartDurationFour(60); } }
              >
                {({ countUpRef }) => (
                  <VisibilitySensor
                    onChange={(isVisible) => {
                      if (isVisible) {
                        setFocus(true);
                      }
                    }}
                  >
                    <span ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
            </p>
            <p className="item__text color--white">
              {t("przerobione m3 drewna", lang)}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Numbers;
