import React from "react";
import Slider from "react-slick";

import t from "../locale";
import ContactFrom from "../components/contactForm";

const SliderSection = ({ lang }) => {
  const settings = {
    arrows: true,
    dots: false,
    infinite: true,
  };

  return (
    <section className="slider-section background--background">
      <div className="container">
        <h2
          className="color--main mb-40"
          dangerouslySetInnerHTML={{
            __html: t("Zapraszamy <br /> do współpracy", lang),
          }}
        />
        <div className="row">
          <div className="col-12 col-lg-6 col-xl-5 slider-section__left">
            <ContactFrom lang={lang} />
          </div>
          <div className="col-12 col-lg-6 col-xl-6 offset-xl-1 slider-section__right">
            <p className="slider-section__right-text">
              {t(
                "Serdecznie zapraszamy do kontaktu. Zakłady produkcyjne i magazyny mieszczą się w dwóch lokalizacjach: w Jordanowie w województwie małopolskim (centrala PalettenWerk) oraz w Gniewięcinie w województwie świętokrzyskim (pod marką Palko)",
                lang
              )}
            </p>
            <div className="slider-section__slider-holder">
              <div className="slider-holder__container">
                <Slider
                  {...settings}
                  className="slider-section__slider"
                  style={{ left: 0 }}
                >
                  <div className="slider__item">
                    <div
                      className="slider__image"
                      style={{
                        backgroundImage: `url(${require("../assets/img/banner.jpg")})`,
                      }}
                    />
                  </div>
                  <div className="slider__item">
                    <div
                      className="slider__image"
                      style={{
                        backgroundImage: `url(${require("../assets/img/banner2.jpg")})`,
                      }}
                    />
                  </div>
                  <div className="slider__item">
                    <div
                      className="slider__image"
                      style={{
                        backgroundImage: `url(${require("../assets/img/banner3.jpg")})`,
                      }}
                    />
                  </div>
                  <div className="slider__item">
                    <div
                      className="slider__image"
                      style={{
                        backgroundImage: `url(${require("../assets/img/banner4.jpg")})`,
                      }}
                    />
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SliderSection;
