import React from "react";

import { Link } from "gatsby";
import BannerMap from "./bannerMap";
import t from "../locale";

const Banner = ({ type, title, subtitle, image, lang }) => (
	<div className={`banner ${type === "404" ? "banner--404" : ""}`}>
		<div className="banner__background">
			<div className="banner__video">
				<video
					src={require("../assets/video/hero-video.mp4")}
					autoPlay
					muted
					playsInline
					loop
				/>
			</div>
			{/* <div
				className="background__image"
				style={{ backgroundImage: `url(${image})` }}
			></div> */}
			<div className="background__map">
				{type !== "404" ? <BannerMap /> : ""}
			</div>
		</div>
		<div className="container container--big baner__container">
			<div className="row banner__content-holder">
				<div className="col-12 banner__holder">
					<div className="holder__line"></div>
					<div className="holder__line"></div>
					<div className="holder__line"></div>
					<div className="holder__line"></div>
					<div className="holder__line"></div>
					<div className="holder__line"></div>
					<div className="holder__line"></div>
					<div className="banner__content">
						<h1
							className="banner__title color--white mb-20"
							dangerouslySetInnerHTML={{ __html: title }}
						></h1>
						{subtitle && (
							<p className="banner__subtitle color--white big text-center">
								{subtitle}
							</p>
						)}
						<Link
							className="btn banner__btn btn--hover-white"
							to={t("/", lang)}
							data-hover="Strona główna"
						>
							{t("Strona główna", lang)}
							<span className="btn-arrows"></span>
						</Link>
					</div>
				</div>
			</div>
		</div>
		<div className="banner__belt">
			<h4 className="belt__text color--white bold">
				<span className="number extra-bold">30</span>
				{t("lat na rynku", lang)}
			</h4>
			<a
				className="btn belt__btn btn--white btn--hover-white"
				href={t("/o-firmie/#30-lat-na-rynku", lang)}
				data-hover={t("Zobacz więcej", lang)}
			>
				{t("Zobacz więcej", lang)}
				<span className="btn-arrows"></span>
			</a>
		</div>
		<div className="banner_ue">
			<a href={t("/dotacje-ue/", lang)} target="_blank" rel="noreferrer">
				{lang === "pl" ? (
					<>
						<img
							src={require("../assets/img/feir.svg")}
							alt=""
							className="img-fluid img_ue"
						/>
					</>
				) : lang === "en" ? (
					<>
						<img
							src={require("../assets/img/feir_en.png")}
							alt=""
							className="img-fluid img_ue"
						/>
					</>
				) : (
					""
				)}
			</a>
			<a href={t("/dotacje-ue/", lang)} target="_blank" rel="noreferrer">
				{lang === "pl" ? (
					<>
						<img
							src={require("../assets/img/uefr.svg")}
							alt=""
							className="img-fluid"
						/>
					</>
				) : lang === "en" ? (
					<>
						<img
							src={require("../assets/img/uefr_en.png")}
							alt=""
							className="img-fluid"
						/>
					</>
				) : (
					""
				)}
			</a>
		</div>
	</div>
);

export default Banner;
