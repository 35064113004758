import React from "react";
import { navigate } from "gatsby";
import axios from "axios";
import $ from "jquery";

import t from "../locale";

class ContactFrom extends React.Component {
  componentDidMount() {
    $(".input-wrapper input, .input-wrapper textarea").focusin(function () {
      $(this).parent().addClass("fill");
    });
    $(".input-wrapper input, .input-wrapper textarea").focusout(function () {
      var inputVal = $(this).val().length;
      if (inputVal > 0) {
        $(this).parent().addClass("fill");
      } else {
        $(this).parent().removeClass("fill");
      }
    });
  }

  state = {
    name: "",
    surname: "",
    email: "",
    phone: "",
    message: "",
    checked: false,
    sent: false,
  };

  resetForm = () => {
    this.setState({
      name: "",
      surname: "",
      email: "",
      phone: "",
      message: "",
      checked: false,
      sent: false,
    });
  };

  formSubmit = (e) => {
    e.preventDefault();

    this.setState({
      sent: true,
    });

    let formData = new FormData();

    formData.set("firstname", this.state.name);
    formData.set("lastname", this.state.surname);
    formData.set("email", this.state.email);
    formData.set("phone", this.state.phone);
    formData.set("message", this.state.message);

    axios({
      method: "post",
      url:
        "https://wp.palettenwerk.pl/wp-json/contact-form-7/v1/contact-forms/5/feedback/",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        this.resetForm();
        $(".input-wrapper").removeClass("fill");
      })
      .catch(() => {
        console.log("Błąd wysyłania wiadomości...");
      });
  };

  render() {
    const lang = this.props.lang;

    return (
      <form
        onSubmit={(e) => {
          this.formSubmit(e);
          if (lang === "pl") {
            navigate("/kontakt-potwierdzenie/");
          } else if (lang === "en") {
            navigate("/en/contact-us-confirmation/");
          } else if (lang === "de") {
            navigate("/de/kontakt-bestatigung/");
          }
        }}
      >
        {/* eslint-disable jsx-a11y/control-has-associated-label */}
        <div className="row">
          <div className="col-sm-6">
            <div className="input-wrapper">
              <label htmlFor="name">{t("Imię", lang)}</label>
              <input
                type="text"
                id="name"
                name="name"
                autoComplete="off"
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="input-wrapper">
              <label htmlFor="surname">{t("Nazwisko", lang)}</label>
              <input
                type="text"
                id="surname"
                name="surname"
                autoComplete="off"
                value={this.state.surname}
                onChange={(e) => this.setState({ surname: e.target.value })}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="input-wrapper">
              <label htmlFor="email">{t("Email", lang)}</label>
              <input
                type="email"
                id="email"
                name="email"
                autoComplete="off"
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
                required
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="input-wrapper">
              <label htmlFor="phone">{t("Telefon", lang)}</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                autoComplete="off"
                value={this.state.phone}
                onChange={(e) => this.setState({ phone: e.target.value })}
              />
            </div>
          </div>
          <div className="col-sm-12">
            <div className="input-wrapper">
              <label htmlFor="message">{t("Treść wiadomości", lang)}</label>
              <textarea
                type="text"
                id="message"
                name="message"
                autoComplete="off"
                value={this.state.message}
                onChange={(e) => this.setState({ message: e.target.value })}
                rows="9"
              ></textarea>
            </div>
          </div>
        </div>
        <label className="check-group">
          <input
            type="checkbox"
            checked={this.state.checked}
            onChange={(e) => this.setState({ checked: !this.state.checked })}
          />
          <span>
            {" "}
            {t(
              "*Wyrażam dobrowolną zgodę na przetwarzanie moich danych osobowych przez PalettenWerk, w celu marketingu bezpośredniego dotyczącego własnych produktów i usług. Obowiązek informacyjny zgodnie z art. 13 ogólnego rozporządzenia o ochronie danych osobowych (RODO) z dnia 27 kwietnia 2016 r. (Dz. Urz. UE L 119 z 04.05.2016): informacje o przetwarzaniu przez nas danych, jak również o prawach osób, których dane dotyczą znajdują się w naszej",
              lang
            )}{" "}
            <a
              href={t("/polityka-prywatnosci/", lang)}
              target="_blank"
              rel="noreferrer"
            >
              {t("polityce prywatności", lang)}
            </a>
            .
          </span>
        </label>
        <button
          type="submit"
          className="btn"
          disabled={!this.state.checked}
          data-hover={
            this.state.sent === true
              ? t("...wysyłanie", lang)
              : t("Wyślij", lang)
          }
        >
          {this.state.sent === true
            ? t("...wysyłanie", lang)
            : t("Wyślij", lang)}
          <span className="btn-arrows"></span>
        </button>
      </form>
    );
  }
}

export default ContactFrom;
