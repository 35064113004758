import React from "react";
import { Link } from "gatsby";

import t from "../locale";

const RecentNews = ({ lastPosts, lang }) => {
	return (
		<section className="recent-news">
			<div className="container">
				<div className="row">
					<div className="col-12 col-lg-6 col-xl-5 col-xxl-4">
						<h2 className="color--main mb-80">
							{t("Aktualności", lang)}
						</h2>
					</div>
				</div>
				<div className="row">
					{lastPosts.lastPosts.edges.map((item, index) => (
						<div className="col-12 col-md-4" key={index}>
							<div className="recent-news__item mb-50">
								<Link to={item.node.uri}>
									<div
										className="item__image mb-45"
										style={{
											backgroundImage: `url(${item?.node?.featuredImage?.node?.sourceUrl})`,
										}}
									></div>
									<h3 className="mb-30">{item.node.title}</h3>
									<p
										className="xs"
										dangerouslySetInnerHTML={{
											__html: item.node.content
												.replace(/(<([^>]+)>)/gi, "")
												.slice(0, 100),
										}}
									></p>
								</Link>
							</div>
						</div>
					))}
					<div className="col-12 recent-news__btn-holder">
						<Link
							className="btn"
							to={t("/aktualnosci/", lang)}
							data-hover={t("Zobacz więcej", lang)}
						>
							{t("Zobacz więcej", lang)}
							<span className="btn-arrows"></span>
						</Link>
					</div>
				</div>
			</div>
		</section>
	);
};

export default RecentNews;
